import md5 from 'md5';
import $Basil from '@/plugins/basil/index.js';

export function cryptoHeaderSign() {
    let token = $Basil.get('token');
    let tokenKey = $Basil.get('tokenKey');
    let separator = 'safety.insurance';
    let noncestr = Math.random().toString(32).substr(2);
    let timestamp = Date.now();
    let signature = md5(token + separator + tokenKey + separator + noncestr + separator + timestamp + separator);
    return { token, tokenKey, noncestr, timestamp, signature };
}
// 通用一级数组生成树结构
export function generateTree(arrs, pidName = 'lpid', children = 'children') {
    let  parentArr=[];
    let menuArray = [];

    parentArr=arrs.filter(function (item,indx,array){
        return (item.lpid==0);
    });
    arrs.forEach((item) => {
        parentArr.forEach((p)=>{
           if (p.ltid==item.lpid){
               if (!p[children]) {
                   p[children] = [];
               }
               p[children].push(item);
           }
        });
    });

    return parentArr;
}

export function getUserEndData() {
    // 根据用户类型，跳转到不同的端
    let userTypeByCode = _.keyBy(userEndArray, 'code');
    let userEndData = userTypeByCode[$Basil.get('loginData')?.type] || {};
    if (userEndData.name !== '展示端' && userEndData.name) {
        document.title = userEndData.name + ' - 安责险管理平台';
    } else {
        document.title = '安责险管理平台';
    }

    return userEndData;
}