import Vue from 'vue';
import Vuex from 'vuex';
Vue.mixin({
    data(){
        return{
            tableCloumnWidth: {
                w50: 50,
                w100: 100,
                w150: 150,
                w200: 200,
                w250: 250,
                w300: 300,
                w350: 350,
                w400: 400,
                w450: 450,
                w500: 500
            },
        };
    },
    computed:{
        ...Vuex.mapState([
            'listAll',
            'XcolumData',
            'token',
            'loginData',
        ])
    },
    methods:{
        ...Vuex.mapMutations(['$Commit','LBMutation'])
    }
})