<template>
    <div class="comp-upload-single-image">
        <div class="item" v-for="(item, index) in fileList" :key="index">
            <!-- <el-image :z-index="9999" :src="item.url" :preview-src-list="[item.url]"></el-image> -->
            <el-image :z-index="9999" :src="item.url" @load="on_load" :preview-src-list="[item.url]"> </el-image>
            <i class="el-icon-error" v-if="!disabled" @click="on_remove(index)"></i>
        </div>
        <el-upload action="http://api.jxyjglxh.com/ueditor/upload?action=uploadimage" :data="{ id: loginData.id, bizCode: 'ALL' }" name="uploadFile" :show-file-list="false" :on-success="on_success" :on-error="on_error" :before-upload="on_before" v-if="!disabled && fileList.length < limit">
            <i class="el-icon-plus"></i>
        </el-upload>
    </div>
</template>

<script>
export default {
    name: 'YiUploadImageM',
    props: {
        // 图片大小
        size: {
            type: Number,
            default: 2
        },
        imgs: {
            type: String,
            default: ''
        },
        // 数量
        limit: {
            type: Number,
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isBase64: {
            type: Boolean,
            default: false
        }
    },
    model: {
        prop: 'imgs',
        event: 'setValue'
    },
    data() {
        return {
            fileList: [],

        };
    },
    watch: {
        imgs(val) {
            this.fileList = this.value_to_fileList(val);
        },
        fileList: {
            handler(val) {

                this.$emit('setValue', this.fileList_to_value(val));
            },
            deep: true
        }
    },
    created() {

        if (this.imgs) {
            this.fileList = this.value_to_fileList(this.imgs);
        }
    },
    methods: {
        fileList_to_value(fileList) {
            if (fileList && fileList.length > 0) {
                return fileList
                    .map((l) => {
                        return l.url;
                    })
                    .join(',');
            }
            return '';
        },
      value_to_fileList(val) {
        if (val) {
          let vs = val.split(',');
          if (vs && vs.length > 0) {

            return vs.map((v) => {
              return {
                url:  v,
                //url:v,
                path: v
              };
            });
          }
        }
        return [];
      },
        // 上传图片成功
        on_success(response, file, fileList) {
          console.log(response);
            if (response.state == "SUCCESS") {
                this.fileList.push({
                    url: 'http://api.jxyjglxh.com/upload-file'+response.url
                });
            } else {
                this.$message.error('上传失败');
            }
        },
        // 上传失败
        on_error(err, file, fileList) {
          console.log('456');
            this.$message.error('上传失败');
        },
        // 删除
        on_remove(index) {
            this.fileList.splice(index, 1);
        },
        // 上传图片之前
        on_before(file) {
            // let size = file.size / 1024 / 1024;
            // if (size > this.size) {
            //     this.$message.error(`上传图片大小不能超过 ${this.size} MB!`);
            //     return false;
            // }
            return true;
        },
        getBase64Image(img) {
            var canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, img.width, img.height);
            var dataURL = canvas.toDataURL('image/png', 0.4);
            return dataURL;
        },
        on_load(e) {
            if (this.isBase64) {
                let image = e.path[0];
                if (image.src.indexOf('http') == 0) {
                    if (image.crossOrigin) {
                        var base64 = this.getBase64Image(image);
                        image.src = base64;
                    } else {
                        image.setAttribute('crossOrigin', 'anonymous');
                    }
                }
            }
        }
    }
};
</script>

<style lang="scss">
.comp-upload-single-image {
    display: flex;
    flex-wrap: wrap;

    .item {
        position: relative;
        margin-right: 10px;
        margin-bottom: 10px;
        width: 178px;
        height: 178px;

        .el-image {
            width: 178px;
            height: 178px;
            border-radius: 6px;
        }

        .el-icon-error {
            position: absolute;
            top: -5px;
            right: -5px;
            font-size: 20px;
            color: #f56c6c;
            background: #ffffff;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    .el-upload {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;

        &:hover {
            border-color: #409eff;
        }
    }
}
</style>
