<template>
    <div class="comp-menu-tree">
        <template v-for="menu in menuData">
            <el-submenu v-if="menu.children" :key="menu.id" :index="menu.sid+'123'">
                <template slot="title">
                    <i v-if="menu.lpid === 0" class="el-icon-menu"></i>
                    <i v-if="menu.lpid !== 0" class="el-icon-s-order"></i>
                    <span> {{ menu.lname}}</span>
                </template>
                <template>
                    <menu-tree :menu-data="menu.children"></menu-tree>
                </template>
            </el-submenu>
            <el-menu-item v-else :index="'/admin/list?sid='+menu.sid" :key="menu.sid">
                <span slot="title"> <i class="el-icon-s-order"></i>{{ menu.lname }}</span>
            </el-menu-item>
        </template>
    </div>
</template>

<script>
export default {
    name: 'MenuTree',
    props: {
        menuData: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {};
    },
    created() {
        this.on_init();
    },
    mounted() {},
    methods: {
        // 页面初始化操作
        on_init() {}
    }
};
</script>

<style lang="scss" scoped>
.comp-menu-tree {
}
</style>
