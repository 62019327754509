import Vue from 'vue';
import Vuex from 'vuex';
import $Basil from '@/plugins/basil/index.js';
import { set as _set } from 'lodash';
Vue.use(Vuex);
const store = new Vuex.Store({
    state:{
        listAll:[],
        XcolumData:[],
        // 令牌
        token: $Basil.get('token') || '',
        // 登录数据
        loginData: $Basil.get('loginData') || {},
    },
    getters:{

    },
    mutations:{
        $Commit(state,payload){
            _set(state,payload.path,payload.data);
        },
        LBMutation: (state, params) => {
            // 判断路径
            if (!params.path) return;
            // 判断数据
            if (!params.data) return;
            // 分割路径
            let keyPath = params.path.split('.');
            // 路径长度
            let keyLength = keyPath.length;
            // 状态别名
            let keySave = state;
            // 默认通过
            let isPass = true;
            // 循环赋值
            for (let i = 0; i < keyLength - 1; i++) {
                keySave = keySave[keyPath[i]];
                if (!keySave) {
                    isPass = false;
                    break;
                }
            }

            // 提前返回
            if (!isPass) return;
            let keyLast = keyPath[keyLength - 1];
            // 判断动作
            if (params.action) {
                if (params.action === '-') keySave[keyLast] = keySave[keyLast] - params.data;
                if (params.action === '+') keySave[keyLast] = keySave[keyLast] + params.data;
                if (params.action === '*') keySave[keyLast] = keySave[keyLast] * params.data;
                if (params.action === '/') keySave[keyLast] = keySave[keyLast] / params.data;
                return;
            } else {
                keySave[keyLast] = params.data;
            }
        }
    },
    actions:{

    }
})
export default store;