export default {
    path: '/display/main/news',
    component: () => import('@/layout/default'),
    children: [
        {
            path: '/',
            component: () => import('@/pages/display/main/news/index.vue')
        }
    ]
};
