<template>
  <div>
  <div class="path_box">
    <div class="path_con">
      <div class="pc_title"><img src="@/assets/images/2_08.png" /><span>{{infosild.parentName}}</span><i></i></div>
      <div class="sub_title">{{infosild.childerSid.lname}}</div>
      <div class="pc_text"> 位置首页>{{infosild.parentName}}>{{infosild.childerSid.lname}}</div>

    </div>
  </div>
  <div class="contact_box">
    <div class="contact_inf">
      <div class="sub_list" v-if="showSublist">
        <dl v-for="(sub,index) in columsubData" :key="index">
          <dd id="sub.sid"> <a @click="$mRouter('/display/main/'+sub.lurl, {childerSid:JSON.stringify(sub),parentName:infosild.parentName})">{{sub.lname}}</a> </dd>
        </dl>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import * as API_COLUM from "@/apis/colum";

export default {
  name:"LeftSlide",
  props:['infosild'],
  data() {
    return{
      columsubData:[],
      showSublist:true,
    }
  },
  created() {

    this.api_columSublist();
  },
  methods: {
    api_columSublist(){
      API_COLUM.listColum({
        parentId:this.infosild.childerSid.ltid
      }).then((res)=>{
        if (res.data.length==0){
          this.showSublist=false;
        }else {
          this.showSublist=true;
          this.columsubData=res.data;
        }

      }).catch((error)=>{
        console.log(error);
      })
    }
  },
  watch:{
    '$route.query': function(){
      this.api_columSublist();

    }
  }
}
</script>

<style scoped>
.path_box { width: 100%; height: 115px; background: url('~@/assets/images/2_04.png') repeat-x; border-bottom: 1px solid #d7d7d7 }
.path_con { width: 1060px; margin: 0 auto; height: 115px }
.pc_title { float: left; width: 195px; height: 84px; background: url('~@/assets/images/2_06.jpg') left bottom no-repeat; padding-top: 31px; line-height: 74px; color: #fff; padding-left: 5px; position: relative;overflow: hidden; }
.pc_title span { font-size: 22px; padding-left: 10px; }
.pc_title i { font-size: 14px; padding-left: 10px; position: absolute; left: 60px; top: 50px }
.pc_title img { margin: 0 0 0 10px; vertical-align: middle }
.sub_title { float: left; margin-left: 55px; padding-top: 31px; height: 84px; line-height: 84px; color: #454545; font-size: 24px ;text-overflow:ellipsis;white-space:nowrap;overflow:hidden; }
.sub_title img { max-height: 84px; }
.pc_text { font-size: 12px; color: #7b7a7a; float: right; line-height: 70px}
.pc_text a { color: #7b7a7a }
.contact_inf { width: 1060px; margin: 0 auto; min-height: 435px; margin-bottom: 30px }
.sub_list { float: left; width: 200px; background: #f5f5f5; padding: 10px 0 60px 0; margin-right: 55px }
.sub_list dl dd { width: 200px; }
.sub_list dl dd a { display: block; width: 149px; height: 53px; padding-left: 25px; margin: 0 auto; border-bottom: 1px solid #ddd; overflow: hidden; text-overflow: hidden; white-space: nowrap; color: #454545; font-size: 15px; background: url('~@/assets/images/2_10.png') 10px center no-repeat; line-height: 53px }
.sub_list dl dd:hover, .sub_list dl li:hover, .sub_on { background: url('~@/assets/images/2_09.jpg') no-repeat }
.sub_list dl dd:hover a, .sub_list dl .sub_on a { border: none; background: url('~@/assets/images/2_11.png') 10px center no-repeat!important; color: #fff }
.sub_list dl ul { }
.sub_list dl ul li { }
.sub_list dl ul li a { display: block; width: 120px; margin-left: 25px; height: 53px; padding-left: 25px; color: #454545; background: url('~@/assets/images/2_10.png') 10px center no-repeat; border-bottom: 1px dashed #ddd; line-height: 53px }
.sub_list dl li:hover a, .sub_list dl ul li.sub_on a { border: none; background: url('~@/assets/images/2_11.png') 10px center no-repeat!important; color: #fff }
</style>