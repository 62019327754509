<template>
    <div class="comp-upload-single-image">
        <el-upload class="avatar-uploader" :file-list="vlaue" :list-type="listType" name="uploadFile" action="http://api.jxyjglxh.com/ueditor/upload?action=uploadimage" :limit="limit" :show-file-list="true" :on-success="on_success" :before-upload="on_before" :on-exceed="on_exceed">
            <slot name="default"></slot>
            <i class="el-icon-plus" v-if="listType !== 'text'"></i>
            <!-- <img src="@/assets/images/headImg.png" alt="" /> -->
        </el-upload>
    </div>
</template>

<script>
export default {
    name: 'YiUploadImage',
    props: {
        // 图片大小
        size: {
            type: Number,
            default: 2
        },
        // 图片类型
        imageType: {
            type: String,
            default() {
                return 'jpg,jpeg,gif,png,webp';
            }
        },
        // 文件列表
        vlaue: {
            type: [Array, String],
            default() {
                return [];
            }
        },
        // 数量
        limit: {
            type: Number,
            default: 1
        },
        // 展示类型
        listType: {
            type: String,
            default: 'picture-card'
        }
    },
    data() {
        return {};
    },
    computed: {},
    created() {
        this.on_init();
    },
    mounted() {},
    methods: {
        // 页面初始化操作
        on_init() {},
        // 图片超出限制
        on_exceed(file) {
            this.$message.error(`上传图片不能超过 ${this.limit} 张!`);
            return false;
        },
        // 上传图片成功
        on_success(res, file) {
            let fileList = _.cloneDeep([]);
            fileList.push({
                name: file.name,
                id: file.response.data.fileId,
                path: file.response.data.absolutePath || file.response.data.relativePath,
                url: file.response.data.absolutePath || this.allData.previewPrefix + file.response.data.relativePath
            });
            this.$emit('input', fileList);
        },
        // 上传图片之前
        on_before(file) {
            console.log('on_before(res): 上传图片之前');
            console.dir(file);
            // 图片类型
            let type = file.type.split('/')[1];
            // 图片大小（M）
            let size = file.size / 1024 / 1024;
            // if (!this.imageType.split(',').includes(type)) {
            //     this.$message.error(`上传图片类型错误!`);
            //     return false;
            // }
            // if (size > this.size) {
            //     this.$message.error(`上传图片大小不能超过 ${this.size} MB!`);
            //     return false;
            // }
            return true;
        }
    }
};
</script>

<style lang="scss" scoped>
.comp-upload-single-image {
    margin: 0; //占位消除css警告
}
</style>
