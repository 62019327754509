import Vue from "vue";
import VueRouter from "vue-router";
import $Basil from '@/plugins/basil/index.js';
// 处理路由重复导航问题
let originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
let originalPush2 = VueRouter.prototype.replace;
VueRouter.prototype.replace = function push(location) {
    return originalPush2.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);
let routeList=[];
let importAll=require.context('@/pages',true,/route\.js$/);
importAll.keys().map((path) => {
    let router = importAll(path).default || importAll(path);
    routeList.push(router);
});
// 导入自动生成的路由文件
let router = new VueRouter({
    routes: routeList,
    scrollBehavior(to, from, savedPosition){
        return {
            x: 0,
            y: 0
        }
    }
});
// 全局前置守卫
router.beforeEach((to, from, next) => {

    const fromPath = from.path;
    if (fromPath != '/') localStorage.setItem('_from_path', fromPath);

    if (to.path.indexOf('/admin')!==-1) {
        if (to.path==='/admin/login'){
            next();
        }else {
            let token = $Basil.get('token');
            if (!token) {
                next('/admin/login');
            } else {
                // 处理未能识别的路由问题
                if (to.matched.length === 0) {
                    next('/404');
                } else {
                    next();
                }
            }
        }
    }else if (to.path==='/'){
        next('/display/main/home')
    }else {
        next();
    }
    // else if(to.path==='/display/main/home'){
    //     next();
    // } else {
    //    next('/display/main/home')
    // }
});

// 全局后置守卫
router.afterEach((to, from) => {
    // 此处写全局后置守卫逻辑
});



export default router;
