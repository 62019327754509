
import Vue from 'vue';

import axios from 'axios';
import basil from '@/plugins/basil/index.js';


import { Message } from 'element-ui';
import { cryptoHeaderSign } from '@/utils/tool.js';
// =============================================
let request = axios.create({
    method: 'get',
    baseURL: 'http://api.jxyjglxh.com',
    // baseURL: process.env.VUE_APP_BASE_API_D,
    // baseURL: 'http://localhost:8077',
    timeout: 1000 * 60 * 30,
    withCredentials: false,
    // responseType: "json",
    // responseEncoding: 'utf8',
    // maxContentLength: 2000,
    headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        // 'Content-Type': 'application/json;utf-8'
    }
});
// 添加请求拦截器
request.interceptors.request.use(
    function (config) {
        // if (config.isLoading === true) {
        //     store.commit('YiMutation', { path: 'urlLoading.' + config.url, data: 'yes' });
        // }
        /**
         * 请求拦截，以下仅为示例，请根据项目需求调整。
         * 在发送请求之前做些什么
         */

        // console.log('config====');
        // console.log(_.cloneDeep(config.data));
        // console.log(_.cloneDeep(config.params));
        // 过滤空参数
        if (_.isObject(config.params)) {
            let newParams = {};
            _.forOwn(config.params, (value, key) => {
                if (value !== null && value !== undefined && value !== '') {
                    newParams[key] = value;
                }
                if (value === 'empty') {
                    newParams[key] = '';
                }
            });
            config.params = newParams;
        }

        if (_.isObject(config.data)) {
            let newParams = {};
            _.forOwn(config.data, (value, key) => {
                if (value !== null && value !== undefined && value !== '') {
                    newParams[key] = value;
                }
                if (value === 'empty') {
                    newParams[key] = '';
                }
            });
            config.data = newParams;
        }

        // 过滤空参数
        // if (config.data) {
        //     let newData = {};
        //     for (let prop in config.data) {
        //         if (config.data.hasOwnProperty(prop) && config.data[prop] !== '') {
        //             newData[prop] = config.data[prop];
        //         }
        //     }
        //     config.data = newData;
        // }

        if (basil.get('token')) {
            let sign = cryptoHeaderSign();
            config.headers = {
                'Content-Type': 'application/json',
                accessToken: sign.token,
                noncestr: sign.noncestr,
                timestamp: sign.timestamp,
                signature: sign.signature
            };
        }
        if (config.url === '/File/uploadFile') {
            config.headers['Content-Type'] = 'multipart/form-data;charset=UTF-8';
        }
        // TODO: 过滤掉为空的参数
        // let newParams = {}
        // if(_.isObject(config.params)){
        //     _.
        // }
        // if (config.method === 'post') {
        //     config.headers['Content-Type'] = 'multipart/form-data';
        //     // config.data = qs.stringify(config.data);
        // }

        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
request.interceptors.response.use(
    function (res) {

        if (res.data.code === 0) {
            return Promise.resolve(res.data);
        }

        // 未登录
        if (res.data.code === 6) {
            Message({
                message: res.data.msg,
                type: 'warning'
            });
            setTimeout(() => {
                location.href = '#/login';
            }, 1500);

            return Promise.reject(res);
        }

        // 参数错误
        if (res.data.code === 400) {
            Message({
                message: res.data.msg,
                type: 'warning'
            });
            return Promise.reject(res);
        }
        if (res.data.code === 405) {
            console.log('=======');
            console.log('未登录');
            location.href = '/#/login';
            return Promise.reject(res);
        }

        return Promise.reject(res);
    },
    function (error) {
        // 对响应错误做点什么
        return Promise.reject(error);
    }
);

// 将接口挂载到 Vue 实例，使用 this.$Api({url:'test.com'}).then(res => {}) 调用接口。
Vue.prototype.$Api = request;
export default request;
