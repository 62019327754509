
import Vue from 'vue'
import App from '@/App.vue'
// 全局路由
import router from '@/router/index.js';
// 插件导入
import '@/autoload/index.js';
// 全局样式
import '@/styles/index.scss';
// 全局存储
import vuex from '@/vuex/index.js';
import _ from 'lodash'
Vue.prototype._ = _
import VueUeditorWrap from 'vue-ueditor-wrap'
Vue.component('vue-ueditor-wrap', VueUeditorWrap)

import vueSeamless from 'vue-seamless-scroll'
Vue.use(vueSeamless)

import dayjs from "dayjs";
Vue.prototype.dayjs = dayjs;

Vue.config.productionTip = false


// 路由带参跳转
Vue.prototype.$mRouter = function (path, query) {
  if (!path) {
    // path = localStorage.getItem('_from_path');
  }
  if (path) {
    this.$router.push({
      path,
      query
    });
  } else {
    this.$router.back();
  }
};
new Vue({
  router: router,
  store: vuex,
  render: h => h(App),
}).$mount('#app')
