import request from '@/request/api.js';


export function listColum(params) {
    return request({
        method: 'GET',
        url: '/xhColumn/listColum',
        params: params
    });
}


export function getInfoBySid(params) {
    return request({
        method: 'GET',
        url: '/xhColumn/getInfoBySid',
        params: params
    });
}
