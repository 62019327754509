<template>
    <el-dialog class="yi-dialog-wrapper" :class="{ 'bg-white': bgWhite }" :visible="visible" :title="dialogTitle" append-to-body :width="dialogSize" :show-close="false">
        <div class="  dialog-wrapper-body">
            <slot name="default"></slot>
        </div>
        <template slot="footer">
            <slot name="footer"></slot>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: 'YiDialogWrapper',
    data() {
        return {
            //防止重复提交
            isDisabled: false
        };
    },
    props: {
        // 【显示|隐藏】属性
        visible: {
            type: Boolean,
            default: false
        },
        // 弹出框标题
        dialogTitle: {
            type: String,
            default() {
                return '';
            }
        },
        // 弹出框宽度
        dialogSize: {
            type: String,
            default() {
                return '1000px';
            }
        },
        bgWhite: {
            type: Boolean,
            default: false
        }
    },
    // 替换默认的v-model绑定值和事件
    model: {
        prop: 'visible',
        event: 'change'
    },
    created() {},
    mounted() {},
    methods: {
        // 简单事件中心
        on(type) {
            if (type === 'cancel') {
                // 关闭弹框
                this.$emit('change', false);
            }
            if (type === 'before-close') {
                // 关闭弹框
                this.$emit('change', false);
            }
        },

    }
};
</script>

<style lang="scss" scoped>
.yi-dialog-wrapper ::v-deep {
    &.bg-white {
        .el-dialog__body {
            background-color: #ffffff;
        }
    }
    .el-dialog__body {
        padding: 10px 10px 10px 0;
        background-color: #ffffff;
    }
    .dialog-wrapper-body {
        max-height: 60vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 20px 10px;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
    }
    .el-form-item__label {
        font-weight: bold;
    }
}
</style>
