<template>
  <div class="content_item" >
    <div class="content_top">
      <div class="content_title">
        {{contentTitle}}
      </div>
      <div class="sanjiao">
      </div>
      <div class="content_boder"></div>
      <div class="content_more" @click="gotoList(contentTitle)">更多>></div>
    </div>
    <div class="content_table">
      <ul class="content_ul">
        <li class="content_text" v-for="(content,index) in dataArray" :key="index">
<!--          <a href="" class="content_a" @click="$mRouter('/display/main/news', {lefthtml:JSON.stringify(queryParam),detail:JSON.stringify(t)})">-->
          <a class="content_a" @click="gotoNews(contentTitle,content)">
            <span class="content_textspan">{{content.ctitle}}</span>
            <span class="content_timespan">{{content.caddtime}}</span>

          </a>
        </li>


      </ul>
    </div>
  </div>
</template>

<script>

import * as API_COLUM from "@/apis/colum";

export default {
    name:"HomeContent",
    props: {
      dataArray: {
        type: Array,
        default() {
          return [];
        }
      },
      SID: {
        type: Number,
        default() {
          return 0;
        }
      },
      contentTitle: {
        type: String,
        default: "",
      }
   },
  data(){
    return{
      xiehuidongtai:{},
      anquanyaowen:{},
      falvfagui:{},
      gongsigonggao:{},

      feimei:{},
      weihua:{},
      gongmaogongye:{},
      yanhuabaozu:{},
      qita:{},
    }
  },
  created() {

    this.api_getHeadItemInfo(this.SID);
    // this.api_getHeadItemInfo(39);
    // this.api_getHeadItemInfo(11);
    // this.api_getHeadItemInfo(40);
    //
    // this.api_getHeadItemInfo(14);
    // this.api_getHeadItemInfo(16);
    // this.api_getHeadItemInfo(17);
    // this.api_getHeadItemInfo(15);
    // this.api_getHeadItemInfo(18);

  },

  methods: {
    gotoNews(name,content){
      if (name == '协会动态') {
        this.$mRouter('/display/main/news', {lefthtml:JSON.stringify({childerSid: this.xiehuidongtai, parentName: this.xiehuidongtai.lname, ismain: 1}),detail:JSON.stringify(content)})
      }else if (name == '安全要闻') {
        this.$mRouter('/display/main/news', {lefthtml:JSON.stringify({childerSid: this.anquanyaowen, parentName: this.anquanyaowen.lname, ismain: 1}),detail:JSON.stringify(content)})
      }else if (name == '法律法规') {
        this.$mRouter('/display/main/news', {lefthtml:JSON.stringify({childerSid: this.falvfagui, parentName: this.falvfagui.lname, ismain: 1}),detail:JSON.stringify(content)})

      }else if (name == '公示公告') {
        this.$mRouter('/display/main/news', {lefthtml:JSON.stringify({childerSid: this.gongsigonggao, parentName: this.gongsigonggao.lname, ismain: 1}),detail:JSON.stringify(content)})

      }else if (name == '非煤矿山') {
        this.$mRouter('/display/main/news', {lefthtml:JSON.stringify({childerSid: this.feimei, parentName: '安全标准化'}),detail:JSON.stringify(content)})

      }else if (name == '危险化学') {
        this.$mRouter('/display/main/news', {lefthtml:JSON.stringify({childerSid:this.weihua, parentName: '安全标准化'}),detail:JSON.stringify(content)})

      }else if (name == '工贸工业') {
        this.$mRouter('/display/main/news', {lefthtml:JSON.stringify({childerSid:this.gongmaogongye, parentName: '安全标准化'}),detail:JSON.stringify(content)})

      }else if (name == '烟花爆竹') {
        this.$mRouter('/display/main/news', {lefthtml:JSON.stringify({childerSid:this.yanhuabaozu, parentName: '安全标准化'}),detail:JSON.stringify(content)})

      }else if (name == '其他') {
        this.$mRouter('/display/main/news', {lefthtml:JSON.stringify({childerSid:this.qita, parentName: '安全标准化'}),detail:JSON.stringify(content)})

      }
    },


    gotoList(name) {
      if (name == '协会动态') {
        this.$mRouter('/display/main/list', {childerSid: JSON.stringify(this.xiehuidongtai), parentName: this.xiehuidongtai.lname, ismain: 1})
      }else if (name == '安全要闻') {
        this.$mRouter('/display/main/list', {childerSid: JSON.stringify(this.anquanyaowen), parentName: this.anquanyaowen.lname, ismain: 1})
      }else if (name == '法律法规') {
        this.$mRouter('/display/main/list', {childerSid: JSON.stringify(this.falvfagui), parentName: this.falvfagui.lname, ismain: 1})
      }else if (name == '公示公告') {
        this.$mRouter('/display/main/list', {childerSid: JSON.stringify(this.gongsigonggao), parentName: this.gongsigonggao.lname, ismain: 1})
      }else if (name == '非煤矿山') {
        this.$mRouter('/display/main/list', {childerSid: JSON.stringify(this.feimei), parentName: '安全标准化'})
      }else if (name == '危险化学') {
        this.$mRouter('/display/main/list', {childerSid: JSON.stringify(this.weihua), parentName: '安全标准化'})
      }else if (name == '工贸工业') {
        this.$mRouter('/display/main/list', {childerSid: JSON.stringify(this.gongmaogongye), parentName: '安全标准化'})
      }else if (name == '烟花爆竹') {
        this.$mRouter('/display/main/list', {childerSid: JSON.stringify(this.yanhuabaozu), parentName: '安全标准化'})
      }else if (name == '其他') {
        this.$mRouter('/display/main/list', {childerSid: JSON.stringify(this.qita), parentName:'安全标准化'})
      }
    },
    api_getHeadItemInfo(SID) {
      API_COLUM.getInfoBySid({
        sid: SID
      }).then((res) => {
        if (SID==8){
          this.xiehuidongtai = res.data[0];
        }else if(SID==39){
          this.anquanyaowen = res.data[0];
        }else if(SID==11){
          this.falvfagui = res.data[0];
        }else if(SID==40){
          this.gongsigonggao = res.data[0];
        }else if(SID==14){
          this.feimei = res.data[0];
        }else if(SID==16){
          this.weihua = res.data[0];
        }else if(SID==17){
          this.gongmaogongye = res.data[0];
        }else if(SID==15){
          this.yanhuabaozu = res.data[0];
        }else if(SID==18){
          this.qita = res.data[0];
        }


      }).catch((error) => {
        console.log(error);
      })
    },
  }

}
</script>

<style scoped>
.content_item{
  float: left;
  width: 350px;
  margin: 10px;
  height: 400px;
  padding: 10px 10px 0 10px;
  position: relative;
  border: #ececec 1px solid;
}
.content_top{
  /*background-color: #ececec;*/
  line-height: 40px;
  display: block;
  border-bottom: solid 2px #0b92ee;
  /*position: relative;*/

}
.content_title{
  display: inline;
  float: left;
  background-color: #0388e3;
  color: white;
  font-weight: bold;
  padding: 0 20px;
}
.sanjiao{
  width: 12px;
  height: 40px;
  background-image: url('~@/assets/images/news-bg.png');
  background-repeat: no-repeat;
  display: block;
  background-size:100% 100%;
  float: left;
  margin-right: -18px;

}
.content_a:hover{
  color: #0b92ee;
}
.content_more{
  display: inline;
  margin-left: 200px;
  color: gray;
}

.content_table{
  font-size: 14px;
  line-height: 27px;
  padding-top: 5px;
}
.content_text{
  background: url('~@/assets/images/icon_blue.jpg') no-repeat left center;
  padding-left: 10px;
}
.content_textspan{
  display: inline-block;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}
.content_timespan{
  float: right;
}
.redspan{
  float: right;
  color: red;
}
</style>